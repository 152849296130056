import { FC, ReactNode, useEffect, useRef } from "react";
import { cn } from "@/utils/cn";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

interface RoundProps {
  children: ReactNode;
  theme?: string;
  className?: string;
  isInfoSection?: boolean;
}

const RoundTitle: FC<RoundProps> = ({ children, theme, className, isInfoSection = false }) => {
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (titleRef.current) {
      if (isInfoSection) {
        gsap.set(titleRef.current, {
          opacity: 0,
          scale: 0.95,
        });

        gsap.to(titleRef.current, {
          opacity: 1,
          scale: 1,
          duration: 0.5,
          ease: "back.out(1.7)",
          scrollTrigger: {
            trigger: titleRef.current,
            start: "top bottom",
            toggleActions: "play none none none",
          },
        });
      } else {
        gsap.set(titleRef.current, { opacity: 0, y: 20 });

        gsap.to(titleRef.current, {
          duration: 0.8,
          opacity: 1,
          y: 0,
          ease: "power3.out",
          delay: 0.1,
          scrollTrigger: {
            trigger: titleRef.current,
            start: "top bottom-=100",
            toggleActions: "play none none none",
          },
        });
      }
    }
  }, [isInfoSection]);

  return (
    <div
      ref={titleRef}
      className={cn(
        "justify-center px-3 py-1 inline-block text-base leading-6 text-gray-500 border-gray-500 border border-solid rounded-full opacity-0",
        {
          "text-white border-white": theme === "light",
          "text-black border-black": theme === "black",
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default RoundTitle;
