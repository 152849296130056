"use client";
import React, { useRef, ReactNode, ReactElement } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { cn } from "@/utils/cn";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

type TitleAnimationProps = {
  children: ReactNode | ReactNode[];
  landingPage?: boolean;
};

const TitleAnimation: React.FC<TitleAnimationProps> = ({ children, landingPage = false }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useGSAP(
    () => {
      if (!containerRef.current) return;

      gsap.set(containerRef.current.querySelectorAll(".letter"), {
        y: "120%",
        opacity: 0,
        visibility: "visible",
      });

      gsap.fromTo(
        Array.from(containerRef.current.querySelectorAll(".letter")),
        { y: "120%", opacity: 0 },
        {
          y: "0%",
          stagger: 0.01,
          ease: "power3.out",
          opacity: 1,
          duration: 0.5,
          scrollTrigger: {
            trigger: containerRef.current,
            start: "top 90%",
            end: "bottom 10%",
            toggleActions: "play none none none",
            once: true,
            markers: false,
          },
        }
      );
    },
    { scope: containerRef }
  );

  const wrapLettersInWords = (text: string) => {
    return text.split(" ").map((word, wordIndex) => (
      <span key={wordIndex} className="word inline-block whitespace-nowrap leading-none align-top">
        {word.split("").map((char, charIndex) => (
          <span
            key={charIndex}
            className={cn(
              "letter-container relative inline-block overflow-hidden h-[1.08em]",
              landingPage && "-mt-[3px] sm:-mt-[4px]"
            )}
          >
            <span className="letter inline-block invisible z-10">{char === " " ? "\u00A0" : char}</span>
            <span className="line absolute bottom-[2px] left-0 w-full"></span>
          </span>
        ))}
        {wordIndex !== text.split(" ").length - 1 && <span className="inline-block">&nbsp;</span>}
      </span>
    ));
  };

  const processChildren = (child: ReactNode): ReactNode => {
    if (typeof child === "string") {
      return wrapLettersInWords(child);
    }

    if (React.isValidElement(child)) {
      const element = child as ReactElement<{ children?: ReactNode }>;
      const props = element.props;
      return React.cloneElement(element, {
        children: React.Children.map(props.children, processChildren),
      });
    }

    return child;
  };

  const animatedChildren = React.Children.map(children, (child, index) => (
    <div key={index} className="line lg:overflow-hidden leading-none -pb-[0.15em] mb-0">
      {processChildren(child)}
    </div>
  ));

  return (
    <div ref={containerRef} className="lg:overflow-hidden leading-none">
      {animatedChildren}
    </div>
  );
};

export default TitleAnimation;
