"use client";
import { FC, ReactNode } from "react";
import TitleAnimation from "./animations/title-animation";
import { useTranslations } from "next-intl";
import RoundTitle from "./round-title";

interface SectionProps {
  title: ReactNode;
  heading: ReactNode;
  children?: ReactNode;
  theme?: string;
}

export const SectionTitle: FC<SectionProps> = ({ title, heading, children, theme }) => {
  const t = useTranslations("Title");

  const titleKey = typeof title === "string" ? t(title) : String(title);
  const headingKey = typeof heading === "string" ? t(heading) : String(heading);

  return (
    <div className="w-full z-30">
      <RoundTitle theme={theme} key={titleKey}>
        {typeof title === "string" ? t(title) : title}
      </RoundTitle>

      <div className="mt-5 flex flex-col lg:flex-row lg:gap-30 w-full">
        <div className="text-4xl sm:text-5xl lg:text-6xl">
          <h2 className="w-full">
            <TitleAnimation key={headingKey}>{typeof heading === "string" ? t(heading) : heading}</TitleAnimation>
          </h2>
        </div>

        <div className="lg:pt-0 w-full lg:w-2/5 xl:w-2/4 lg:ml-auto">{children}</div>
      </div>
    </div>
  );
};
