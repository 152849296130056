"use client";
import { cn } from "@/utils/cn";
import { Logo } from "./icons";
import Link from "next/link";
import { useTranslations } from "next-intl";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { getLocalizedHref } from "@/i18n/slug-mappings";
import { Locale } from "@/i18n/config";

const LanguageSwitcher = dynamic(() => import("./language-switcher"), {
  ssr: false,
});

interface MenuItemProps {
  text: string;
  href?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ text }) => {
  return <div>{text}</div>;
};

export interface FooterProps {
  theme?: "light" | "dark";
}

export const Footer: React.FC<FooterProps> = ({ theme }) => {
  const t = useTranslations("Footer");
  const pathname = usePathname();
  const locale = (pathname.split("/")[1] as Locale) || "it";

  const menuItems = [
    { text: "home", href: { it: "/", en: "/" } },
    { text: "portfolio", href: { it: "/portfolio", en: "/portfolio" } },
    { text: "services", href: { it: "/servizi", en: "/services" } },
    { text: "aboutUs", href: { it: "/chi-siamo", en: "/about-us" } },
    { text: "career", href: { it: "/carriera", en: "/career" } },
    { text: "blog", href: { it: "/blog", en: "/blog" } },
  ];

  const localizedMenuItems = menuItems.map((item) => ({
    ...item,
    href: getLocalizedHref(item.href, locale),
  }));
  const socialLinks = [
    { name: "Linkedin", href: "https://www.linkedin.com/company/armoniadigital/" },
    { name: "Facebook", href: "https://www.facebook.com/armonia.io" },
    { name: "Instagram", href: "https://www.instagram.com/armonia.io/" },
  ];

  const backgroundColor = theme === "light" ? "bg-white" : "bg-dark-gray";
  const textColor = theme === "light" ? "text-dark-gray" : "text-white";
  const colorLogo = theme === "light" ? "dark-gray" : "white";

  return (
    <footer
      className={cn(
        "flex flex-wrap flex-col px-6 md:px-9 py-16 rounded-t-3xl  max-md:max-w-full relative z-30",
        backgroundColor
      )}
    >
      <div
        className={cn(
          "flex flex-col gap-14 lg:flex-row lg:justify-between self-stretch text-start leading-5 max-md:max-w-full",
          textColor
        )}
      >
        <div className="lg:w-1/3">
          <Link href="/" aria-label="Navigate to home">
            <Logo className={`aspect-[5.26] max-w-xs sm:max-w-sm md:max-w-md h-auto`} color={colorLogo} />
          </Link>
        </div>
        <div className="flex flex-col md:w-2/4 lg:w-2/5 pb-12 lg:pb-24">
          <div className="grow justify-end max-md:max-w-full">
            <div className="flex flex-col sm:flex-row sm:justify-between max-md:gap-10">
              <div className={cn("flex flex-col gap-4 sm:gap-7 text-lg w-1/3 sm:w-auto min-w-20", textColor)}>
                <h3 className="font-medium">Menù</h3>
                <div className="leading-7">
                  {localizedMenuItems.map((item) => (
                    <div key={item.text}>
                      <Link href={item.href}>
                        <h4>
                          <MenuItem text={t(item.text)} />
                        </h4>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>

              <div className={cn("flex flex-col gap-4 sm:gap-7 text-lg w-1/3 sm:w-auto min-w-20", textColor)}>
                <h3 className="font-medium">Social</h3>
                <div className="flex flex-col">
                  {socialLinks.map((social) => (
                    <Link href={social.href} key={social.name} target="_blank">
                      <h4>{social.name}</h4>
                    </Link>
                  ))}
                </div>
              </div>

              <div className={cn("flex flex-col gap-4 sm:gap-7 self-stretch text-lg sm:w-1/3 min-w-20", textColor)}>
                <h3 className="font-medium">Contact</h3>
                <div className="flex flex-col">
                  <Link href="tel:+393313998288">
                    <h4>+39 331 399 8288</h4>
                  </Link>
                  <Link href="mailto:hi@armonia.io">
                    <h4>hi@armonia.io</h4>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col-reverse sm:flex-row sm:justify-between gap-4 text-sm md:items-end">
        <div className="flex flex-col max-md:max-w-full">
          <h5 className={cn(textColor, "mb-4")}>
            <span className={cn(textColor, "mb-4")}>© Armonia S.r.l. 2025</span>
          </h5>
          <span className={cn(textColor)}>
            <h5>P.IVA 05971350656</h5>
            <h5 className={"mb-4"}>{t("companyName")}: Armonia S.r.l.</h5>
            <h5>Viale Degli Eucalipti 38</h5>
            <h5 className={"mb-4"}>84134 Salerno (SA)</h5>
            <h5>Rea 487603</h5>
            <h5>{t("capital")} 10.000,00 euro</h5>
          </span>
        </div>
        <div className={cn("flex items-end", textColor)}>
          <div className="flex items-center gap-5 max-md:mb-8">
            <LanguageSwitcher theme={theme} />
            <Link href="/privacy">
              <h5>Privacy Policy</h5>
            </Link>
            <Link href="/cookies">
              <h5>Cookie Policy</h5>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
